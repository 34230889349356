.p-treetable.p-component {
  .p-treetable-tbody {
    tr {
      td {
        padding-top: 15px;
        padding-bottom: 15px;

        button.p-unselectable-text {
          &[style*="visibility: hidden;"] {
            display: none;
          }
        }
      }
    }
  }
}